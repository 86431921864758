import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  Observable,
  Subject,
  catchError,
  map,
  merge,
  of,
  startWith,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { LegacyAccountSubscription } from '../account/account-subscription/legacy-account-subscription';
import { AccountService } from '../account/account.service';
import { WINDOW } from '../core/providers/window';
import { convertDateToISO8601 } from '../core/utils/time';
import { ApiErrorCode } from '../shared/api/enums/api-error-code';
import { ApiError } from '../shared/api/interfaces/api-error';
import { ApiErrorSnackBarComponent } from '../shared/snack-bars/api-error-snack-bar/api-error-snack-bar.component';
import { SignUpData } from './auth.service';
import { LegacyLawyerUser, LegacyUser } from './legacy-user';
import { TokenService } from './token.service';
import { ACTIVE_STATUSES, LaywerUserInfos, User, UserStatus } from './user';
import { UserPermission } from './user-permissions';

export interface LegacyLoginResult {
  token: string;
  user: LegacyUser;
}

export interface SignUpResult {
  error: false;
  message: '';
  user: { public: LegacyUser };
  subscription: LegacyAccountSubscription;
}

export interface SignUpError {
  error: true;
  message: string;
  user: null;
}

@Injectable({
  providedIn: 'root',
})
export class LegacyAuthService {
  private readonly token = inject(TokenService);
  private readonly router = inject(Router);
  private readonly window = inject(WINDOW);
  private readonly http = inject(HttpClient);
  private readonly account = inject(AccountService);
  private readonly snackBar = inject(MatSnackBar);

  private readonly refreshUserSubject = new Subject<void>();

  readonly isAuthenticated = computed(() => !!this.token.value());
  readonly user = toSignal(
    merge(
      this.account.updated,
      this.refreshUserSubject,
      toObservable(this.token.value),
    ).pipe(switchMap(() => this.getCurrentUser().pipe(startWith(undefined)))),
  );

  getCurrentUser(): Observable<User | null> {
    if (!this.isAuthenticated()) {
      return of(null);
    }
    return this.http.get<LegacyUser>(`${environment.apiUrl}/user/profile`).pipe(
      map((user) => this.formatUser(user)),
      catchError(() => of(null)),
    );
  }

  currentUserHasPermission(permission: UserPermission): boolean {
    const user = this.user();
    return !!user && user.permissions.includes(permission);
  }

  currentUserHasSomePermissions(permissions: UserPermission[]): boolean {
    return (
      permissions.length === 0 ||
      permissions.some((permission) =>
        this.currentUserHasPermission(permission),
      )
    );
  }

  currentUserHasStatus(...statuses: UserStatus[]): boolean {
    const user = this.user();
    return !!user && statuses.includes(user.status);
  }

  sendVerificationCodeBySMS(phoneNumber: string): Observable<void> {
    return this.http
      .get<{ message: string }>(`${environment.apiUrl}/me/code`, {
        params: { mobile: phoneNumber },
      })
      .pipe(
        switchMap((result) =>
          result.message === 'ok'
            ? of(undefined)
            : throwError(() => ({
                code: `CHECK_ACCOUNT_${result.message.toUpperCase()}`,
                description: 'Cannot check account',
              })),
        ),
        tap({
          error: (error: ApiError) => {
            this.snackBar.openFromComponent(ApiErrorSnackBarComponent, {
              data: error,
              duration: 6000,
              panelClass: 'jrp-error-snack-bar',
            });
          },
        }),
      );
  }

  sendVerificationCodeByEmail(email: string): Observable<void> {
    return this.http
      .get<{ message: string }>(`${environment.apiUrl}/me/code`, {
        params: { email },
      })
      .pipe(
        switchMap((result) =>
          result.message === 'ok'
            ? of(undefined)
            : throwError(() => ({
                code: `CHECK_ACCOUNT_${result.message.toUpperCase()}`,
                description: 'Cannot check account',
              })),
        ),
        tap({
          error: (error: ApiError) => {
            this.snackBar.openFromComponent(ApiErrorSnackBarComponent, {
              data: error,
              duration: 6000,
              panelClass: 'jrp-error-snack-bar',
            });
          },
        }),
      );
  }

  checkAccount(code: string): Observable<void> {
    return this.http
      .post<{ code_verified: boolean }>(`${environment.apiUrl}/me/code`, {
        code,
      })
      .pipe(
        switchMap((result) =>
          result.code_verified
            ? of(undefined)
            : throwError(() => ({
                code: ApiErrorCode.VerificationCodeNotMatching,
                description: 'No verification code matching',
              })),
        ),
        tap({
          next: () => {
            this.refreshUserSubject.next();
          },
          error: (error: ApiError) => {
            this.snackBar.openFromComponent(ApiErrorSnackBarComponent, {
              data: error,
              duration: 6000,
              panelClass: 'jrp-error-snack-bar',
            });
          },
        }),
      );
  }

  signIn(
    email: string,
    password: string,
    cloudflareTurnstileToken: string,
  ): Observable<string> {
    return this.http
      .post<LegacyLoginResult>(`${environment.apiUrl}/login`, {
        login: email,
        password,
        cloudflareTurnstileToken,
      })
      .pipe(
        map(({ token }) => {
          this.token.value.set(token);
          return token;
        }),
      );
  }

  signInWithToken(token: string): void {
    this.token.value.set(token);
  }

  signInByIPAddress(): Observable<string | null> {
    return this.http
      .get<LegacyLoginResult | false>(`${environment.apiUrl}/ipauth`)
      .pipe(
        map((result) => {
          if (result) {
            this.token.value.set(result.token);
            return result.token;
          }
          return null;
        }),
      );
  }

  signUp(data: SignUpData): Observable<User> {
    return this.http
      .post<SignUpResult | SignUpError>(`${environment.apiUrl}/users`, {
        coupon_code: data.promoCode,
        email: data.email,
        nom: data.fullname,
        optin: data.newsletterAccepted,
        password: data.password,
        rgpd: data.gdprAccepted,
        id_occupation: data.occupationId,
        bar: data.bar,
        nb_users: 1,
        trial: 1,
        cloudflareTurnstileToken: data.cloudflareTurnstileToken,
      })
      .pipe(
        switchMap((result) =>
          result.error
            ? throwError(() => ({
                code: `SIGN_UP_${result.message.toUpperCase()}`,
                description: 'Cannot sign up',
              }))
            : of(result.user.public),
        ),
        map((user) => this.formatUser(user)),
        tap({
          error: (error: ApiError) => {
            this.snackBar.openFromComponent(ApiErrorSnackBarComponent, {
              data: error,
              duration: 6000,
              panelClass: 'jrp-error-snack-bar',
            });
          },
        }),
      );
  }

  askForNewPassword(email: string): Observable<void> {
    return this.http
      .post<boolean>(`${environment.apiUrl}/login/reminder`, {
        email,
      })
      .pipe(
        switchMap((accepted) =>
          accepted
            ? of(undefined)
            : throwError(() => ({
                code: 'NO_ACCOUNT_ASSOCIATED',
                description:
                  'There is no account associated to this email address',
              })),
        ),
        tap({
          error: (error: ApiError) => {
            this.snackBar.openFromComponent(ApiErrorSnackBarComponent, {
              data: error,
              duration: 6000,
              panelClass: 'jrp-error-snack-bar',
            });
          },
        }),
      );
  }

  logout(): void {
    const redirectionUrl = '/connexion';

    this.token.value.set(null);
    if (this.window === this.window.top) {
      this.router.navigateByUrl(redirectionUrl);
    } else {
      this.window.open(redirectionUrl, '_top');
    }
  }

  private formatUser(legacyUser: LegacyUser): User {
    const user: User = {
      id: legacyUser.id,
      fullname: legacyUser.nom,
      email: legacyUser.email,
      phone: legacyUser.mobile,
      newsletterConfig: {
        days: Object.values(legacyUser.newsletter_config.days)
          .filter((value) => value !== '')
          .map(Number),
        newsCategoryIds: Object.values(
          legacyUser.newsletter_config.topics_selected,
        ).map(Number),
        hour: Number(legacyUser.newsletter_config.hour),
      },
      verified: Boolean(legacyUser.is_verified),
      expireAt: convertDateToISO8601(legacyUser.expiration),
      permissions: this.getPermissions(legacyUser),
      status: legacyUser.statut_paiement,
      category: legacyUser.profile,
    };
    if (this.isLawyer(legacyUser)) {
      user.lawyerInfos = this.formatLegacyUserLaywerInfos(legacyUser);
    }
    return user;
  }

  private getPermissions(legacyUser: LegacyUser): UserPermission[] {
    const isActive = ACTIVE_STATUSES.includes(legacyUser.statut_paiement);
    const useProxy = legacyUser.is_proxy_access;
    let permissions: UserPermission[] = [];

    if (!useProxy) {
      permissions = permissions.concat('access-account');

      if (isActive) {
        permissions = permissions.concat(
          'access-detect',
          'access-documents',
          'access-search-history',
          'access-alerts',
          'access-newsletter-configuration',
        );
      }
    }
    return permissions;
  }

  private formatLegacyUserLaywerInfos(user: LegacyLawyerUser): LaywerUserInfos {
    return {
      oathDate: user.oathDate,
      areasOfExpertise: user.skillsDetails,
      bar: user.bar,
      office: {
        address: user.officeAddress,
        city: user.officeCity,
        email: user.officeEmail,
        fax: user.officeFax,
        phone: user.officePhone,
        zipCode: user.officePostcode,
      },
      gdpr: {
        accepted: Boolean(user.gdpr),
        label: user.gdprLabel,
      },
      facebook: user.facebook,
      linkedIn: user.linkedIn,
      twitter: user.twitter,
      website: user.website,
    };
  }

  private isLawyer(user: LegacyUser): user is LegacyLawyerUser {
    return user.is_lawyer;
  }
}
